import ApiAdmin from "../../config/AppAdmin";

export const updateOrderStatus = async (status_id, newStatus) => {
  try {
    const response = await ApiAdmin.patch(`/orderStatus/${status_id}`, {
      track_status: newStatus,
    });

    if (response.status !== 200) {
      throw new Error("Failed to update status");
    }

     alert("Order status updated successfully");
  } catch (error) {
    console.error("Error updating status:", error);
    alert("Error updating order status. Please try again.");
  }
};
