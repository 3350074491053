import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";

const AdminEditDialog = ({ open, onClose, adminData, onInputChange, onUpdate }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Edit Admin</DialogTitle>
    <DialogContent>
      {["admin_name", "admin_contact", "phone", "address", "role", "salary", "salary_status"].map((field) => (
        <TextField
          key={field}
          label={field.replace("_", " ").toUpperCase()}
          fullWidth
          value={adminData[field]}
          onChange={onInputChange}
          name={field}
          sx={{ mb: 2 }}
        />
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cancel
      </Button>
      <Button onClick={onUpdate} color="primary">
        Update
      </Button>
    </DialogActions>
  </Dialog>
);

export default AdminEditDialog;
