import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";


const OrderDataGrid = ({ orders, columns }) => {
  const rows = orders.map((order, index) => ({
    id: order.order_id,
    order_instructions: order.order_instructions || "No instructions",
    order_items: (() => {
      try {
        const items = JSON.parse(order.order_items);
        return items.map((item) => `${item.name}`).join(", ");
      } catch {
        return "Invalid data";
      }
    })(),
    order_quantity: order.order_quantity || "N/A",
    track_status: order.track_status,
  }));

  const sortedRows = rows.sort((a, b) => b.id - a.id);
  const finalRows = sortedRows.map((row, index) => ({
    ...row,
    serial_number: index + 1,
  }));

  return (
    <Box sx={{ height: "auto", width: "90%" }}>
      <DataGrid rows={finalRows} columns={columns} pageSize={5} />
    </Box>
  );
};

export default OrderDataGrid;
