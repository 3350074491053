import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import ApiAdmin from "../../config/AppAdmin";
import dynamicSocketUrl  from "../../config/socketUrl";
import io from "socket.io-client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const {dynamicSocketUrl}=DynamicSocketUrl;
const socketUrl=dynamicSocketUrl();

const fetchOrders = async () => {
  const response = await ApiAdmin.get(`/joinOrder`);
  if (response.status !== 200) {
    throw new Error("Failed to fetch orders");
  }
  return response.data.data[0] || [];
};
const useOrders = () => {
  const { data, refetch, isLoading, isError } = useQuery({
    queryKey: ["orders"],
    queryFn: fetchOrders,
  });

  const [newOrderCount, setNewOrderCount] = useState(0);

  useEffect(() => {
    const socket = io(socketUrl);

    socket.on("orderAdded", () => {
      refetch();
      setNewOrderCount((prevCount) => prevCount + 1);
      toast.success(" New order", { position: "top-right" });
    });

    socket.on("trackStatusUpdated", (data) => {
      toast.success(
        `Track status updated for Order ID ${data.order_id}: ${data.track_status}`
      );
      refetch();
    });

    return () => {
      socket.off("orderAdded");
      socket.off("trackStatusUpdated");
      socket.close();
    };
  }, [refetch]);

  // Function to reset order count
  const resetOrderCount = (shouldReset = true) => {
    if (shouldReset) {
      setNewOrderCount(1);  // Set to 1 to show badge
    }
  };

  return { data, refetch, isLoading, isError, newOrderCount, resetOrderCount };
};

export default useOrders;