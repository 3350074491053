import React, { useState } from 'react';
import { Box, Typography,IconButton, } from '@mui/material';
import { updateOrderStatus } from '../orderTable/updateOrderStatus';
import useOrders from '../orderTable/fetchedOrders';
import OrderDataGrid from './orderDataGrid';
import OrderStatusPopover from './orderStatusPopover';
import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";

const HomeComponent = () => {
  const { data: orders, isLoading, error } = useOrders();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [localOrders, setLocalOrders] = useState([]);
   console.log("orders",orders);
  React.useEffect(() => {
    if (orders) {
      setLocalOrders(orders);
    }
  }, [orders]);

  const handleRowClick = (params, event) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrder(params.row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedOrder(null);
  };

  const handleStatusChange = async (newStatus) => {
    if (selectedOrder) {
      await updateOrderStatus(selectedOrder.id, newStatus);

      setLocalOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.order_id === selectedOrder.id
            ? { ...order, track_status: newStatus }
            : order
        )
      );

      handleClose();
    }
  };

  if (isLoading) {
    return <div>Loading orders...</div>;
  }

  if (error) {
    return <div>Error fetching orders: {error.message}</div>;
  }

  const columns = [
    { field: 'serial_number', headerName: 'SN', width: 90 },
    { field: 'order_instructions', headerName: 'Order Instructions', width: 250 },
    { field: 'order_items', headerName: 'Order Items', width: 250 },
    { field: 'order_quantity', headerName: 'Order Quantity', width: 150 },
    {
      field: 'track_status',
      headerName: 'Order Status',
      width: 180,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(e) => handleRowClick(params, e)}>
          <Typography>{params.value}</Typography>
          <IconButton sx={{ ml: 1 }}>
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <Typography variant="h4">Kitchen Orders</Typography>
      <OrderDataGrid orders={localOrders} columns={columns} handleRowClick={handleRowClick} />
      <OrderStatusPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleStatusChange={handleStatusChange}
      />
    </Box>
  );
};

export default HomeComponent;
