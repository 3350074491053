import React from "react";
import { TableRow, TableCell, TableHead } from "@mui/material";

const TableHeader = () => {
  return (
    <TableHead
      sx={{
        fontSize: {
          xs: "4px",
          sm: "6px",
          md: "8px",
          lg: "10px",
        },
      }}
    >
      <TableRow>
        {[
          "S.N",
          "Date and Time",
          "Customer Name",
          "Customer Address",
          "Customer Phone",
          "Customer Email",
          "Order Instructions",
          "Address Instructions",
          "Order Items",
          "Order Quantity",
          "Total Price",
          "Table No.",
          "Tracking ID",
          "Track Status",
          "Payment Mode",
          "Payment Status",
          "Order Status"
        ].map((header) => (
          <TableCell
            key={header}
            sx={{
              fontWeight: "bold",
              fontSize: {
                xs: "4px",
                sm: "8px",
                md: "10px",
                lg: "14px",
              }
             
            }}
          >
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
