import React, { useState } from "react";
import ApiClient from "../../config/AppAdmin";
import {
  Box,
  TextField,
  Button,
  Typography,
 
  Checkbox,
  FormControl,
   FormControlLabel,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AdminSignupForm = () => {
  const [formData, setFormData] = useState({
    admin_name: "",
    admin_contact: "",
    admin_password: "",
    otp :"",
    role: "", // default value
    address:"",
    phone:""

  });

  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.role) {
      setMessage({
        type: "error",
        text: "Please select a role.",
      });
      return; // Prevent form submission
    }
    try {
      const response = await ApiClient.post("/admin/signup", formData);

      if (response.status === 200 || response.status === 201) {
        setMessage({ type: "success", text: response.data.message });
         // Clear the form data after successful submission
         setFormData({
            admin_name: "",
            admin_contact: "",
            admin_password: "",
            otp: "",
            role: "", // reset to default
            address:"",
            phone:""
          });
      } else {
        setMessage({ type: "error", text: response.data.message });
      }
    } catch (error) {
      console.error("Signup error:", error);
      setMessage({
        type: "error",
        text:
          error.response?.data?.message ||
          "An error occurred while signing up.",
      });
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: "auto",
        mt: 5,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h4" textAlign="center" mb={3}>
        Staff Registration
      </Typography>

      {message && (
        <Alert severity={message.type} sx={{ mb: 2 }}>
          {message.text}
        </Alert>
      )}

      <form onSubmit={handleSubmit} autoComplete="off">
        <TextField
          label=" Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="admin_name"
          value={formData.admin_name}
          onChange={handleChange}
          required
        />
         <TextField
          label="Address"
          variant="outlined"
          fullWidth
          margin="normal"
          name="address"
          value={formData.address}
          onChange={handleChange}
          required
        />
         <TextField
          label=" Phone"
          variant="outlined"
          fullWidth
          margin="normal"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <TextField
          label=" Email"
          variant="outlined"
          fullWidth
          margin="normal"
          name="admin_contact"
          value={formData.admin_contact}
          onChange={handleChange}
          required
          autoComplete="new-email" // Prevents browser autofill
        />

        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          margin="normal"
          name="admin_password"
          value={formData.admin_password}
          onChange={handleChange}
          required
          autoComplete="new-password" // Prevents browser autofill
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControl component="fieldset" fullWidth margin="normal"  required>
          <Typography variant="body1" mb={1} >
            Role
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.role === "admin"}
                  onChange={() =>
                    setFormData((prev) => ({ ...prev, role: "admin" }))
                  }
                  name="role"
                  color="primary"
                 
                />
              }
              label="Admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.role === "cashier"}
                  onChange={() =>
                    setFormData((prev) => ({ ...prev, role: "cashier" }))
                  }
                  name="role"
                  color="primary"
                />
              }
              label="Cashier"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.role === "kitchen"}
                  onChange={() =>
                    setFormData((prev) => ({ ...prev, role: "kitchen" }))
                  }
                  name="role"
                  color="primary"
                />
              }
              label="Kitchen"
            />
          </Box>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
        >
          Register
        </Button>
      </form>
    </Box>
  );
};

export default AdminSignupForm;
