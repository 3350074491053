import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ApiAdmin from "../../config/AppAdmin";

function Enquiry() {
  const [filteredOrders, setFilteredOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await ApiAdmin.get(`/enquiry`);
        const fetchedOrders = response.data.data[0] || []; // Ensure data exists

        // Convert created_at to local device time format
        const ordersWithLocalTime = fetchedOrders.map((order) => ({
          ...order,
          created_at: new Date(order.created_at).toLocaleString(), // Convert to local time format
        }));

        setFilteredOrders(ordersWithLocalTime); // Set the filtered orders directly
      } catch (error) {
        console.error("Error fetching orders:", error);
        setFilteredOrders([]); // Set filteredOrders to an empty array on error
      }
    };

    fetchOrders();
  }, []);

  // Define columns for the DataGrid
  const columns = [
    { field: "userName", headerName: "Name", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "message", headerName: "Message", width: 300 },
    { field: "created_at", headerName: "Created At", width: 500 },
  ];

  return (
    <div style={{ display: "flex", justifyContent: "center", height: 400, width: "80%" }}>
      <div style={{ width: "100%" }}>
        <h1>Enquiries</h1>
        <DataGrid
          rows={filteredOrders.map((order, index) => ({
            id: index, // Required for DataGrid row identification
            ...order,
          }))}
          columns={columns}
          pageSize={4}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
         
        />
      </div>
    </div>
  );
}

export default Enquiry;