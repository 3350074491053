import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAdmins, updateAdmin } from "../../redux/slice/staffSlice";
import { Box, Typography } from "@mui/material";
import AdminDataGrid from "./adminDataGrid";
import AdminEditDialog from "./adminEditDialog";

const AdminTable = () => {
  const dispatch = useDispatch();
  const { admins, loading, error } = useSelector((state) => state.staff);

  // State for managing the dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [adminData, setAdminData] = useState({
    admin_name: "",
    admin_contact: "",
    phone: "",
    address: "",
    role: "",
    salary: "",
    salary_status: "",
  });

  // Fetch admins data on component mount
  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  const handleEditClick = (admin) => {
    setSelectedAdmin(admin);
    setAdminData({
      admin_name: admin.admin_name,
      admin_contact: admin.admin_contact,
      phone: admin.phone,
      address: admin.address,
      role: admin.role,
      salary: admin.salary,
      salary_status: admin.salary_status,
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedAdmin(null);
    setAdminData({
      admin_name: "",
      admin_contact: "",
      phone: "",
      address: "",
      role: "",
      salary: "",
      salary_status: "",
    });
  };

  const handleUpdate = () => {
    if (selectedAdmin) {
      dispatch(updateAdmin({ aid: selectedAdmin.aid, ...adminData }));
      handleDialogClose(); // Close the dialog after updating
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Staff Record
      </Typography>

      {loading && <Typography>Loading...</Typography>}
      {error && (
        <Typography color="error" variant="body1">
          Error: {error}
        </Typography>
      )}

      {!loading && !error && admins.length === 0 && (
        <Typography>No data available.</Typography>
      )}

      {/* Admin Data Grid */}
      {!loading && !error && admins.length > 0 && (
        <AdminDataGrid admins={admins} onEditClick={handleEditClick} />
      )}

      {/* Dialog for editing admin details */}
      <AdminEditDialog
        open={openDialog}
        onClose={handleDialogClose}
        adminData={adminData}
        onInputChange={(e) => setAdminData((prevData) => ({
          ...prevData,
          [e.target.name]: e.target.value,
        }))}
        onUpdate={handleUpdate}
      />
    </Box>
  );
};

export default AdminTable;
