import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, IconButton, Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ApiAdmin from "../../config/AppAdmin";
import DateTimeInput from "./dateTimeInput";
const StatusField = ({
  isEditing,
  statusField,
  item,
  editedItem,
  setEditedItem,
  id,
}) => {
  const parseJSONSafely = (str, fallback) => {
    if (!str) return fallback;
    try {
      return JSON.parse(str);
    } catch {
      return fallback;
    }
  };

  const formatDateTime = (date) => {
    const pad = (n) => (n < 10 ? `0${n}` : n);
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  

  const currentDateTime = useMemo(() => formatDateTime(new Date()), []);

  const status = useMemo(() => {
    const parsed = parseJSONSafely(item[statusField], []);
    return Array.isArray(parsed) && parsed.length > 0
      ? parsed[0]
      : {
          status: false,
          "start time": currentDateTime,
          "end time": "",
          price: 0,
        };
  }, [item, statusField, currentDateTime]);

  const [checkboxStatus, setCheckboxStatus] = useState(status.status);
  const [startTime, setStartTime] = useState(status["start time"]);
  const [endTime, setEndTime] = useState(status["end time"]);
  const [price, setPrice] = useState(status.price || 0);
  
  const [timeError, setTimeError] = useState("");

  useEffect(() => {
    setCheckboxStatus(status.status);
    setStartTime(status["start time"]);
    setEndTime(status["end time"]);
    setPrice(status.price || 0);
  }, [status]);

  const updateDatabase = async () => {
    try {
      if (checkboxStatus && (!startTime || !endTime)) {
        
        return;
      }
      if (new Date(endTime) <= new Date(startTime)) {
        setTimeError("End Time must be greater than Start Time.");
        return;
      }

    
      const updatedStatus = JSON.stringify([
        {
          status: checkboxStatus,
          "start time": startTime,
          "end time": endTime,
          price: checkboxStatus ? price : 0,
        },
      ]);

      setEditedItem((prevItem) => ({
        ...prevItem,
        [statusField]: updatedStatus,
      }));

      await ApiAdmin.patch(`/itemstatus/${id}`, {
        status: checkboxStatus,
        "start time": startTime,
        "end time": endTime,
        price: checkboxStatus ? price : 0,
      });

      alert("Status updated successfully!");
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Failed to update status. Please try again.");
    }
  };

  return isEditing ? (
    <Box>
      <Checkbox
        checked={checkboxStatus}
        onChange={(e) => {
          setCheckboxStatus(e.target.checked);
          if (!e.target.checked) {
            setStartTime(currentDateTime);
            setEndTime("");
            setPrice(0);
          }
        }}
        sx={{
          color: "blue",
          "&.Mui-checked": {
            color: "blue",
          },
        }}
      />
      {checkboxStatus && (
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <DateTimeInput
            label="Start Time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            error={timeError}
          />
          <DateTimeInput
            label="End Time"
            value={endTime}
            onChange={(e) => {
              setEndTime(e.target.value);
              if (new Date(e.target.value) <= new Date(startTime)) {
                setTimeError("End Time must be greater than Start Time.");
              } else {
                setTimeError("");
              }
            }}
            error={timeError}
          />
          <label>
            Price:
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              style={{
                display: "block",
                margin: "8px 0",
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          </label>
        </Box>
      )}

      <IconButton onClick={updateDatabase} color="primary" sx={{ mt: 2 }}>
        <SaveIcon />
      </IconButton>
    </Box>
  ) : (
    <Box>
      {checkboxStatus
        ? `${startTime || "N/A"} : ${endTime || "N/A"}  Price: Rs ${price}`
        : "False"}
    </Box>
  );
};

export default StatusField;
