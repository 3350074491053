import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import SaveIcon from "@mui/icons-material/Save";

const PaymentStatusSelect = ({ handlePaymentStatusChange,handlePaymentUpdate,newPaymentStatus }) => {
  return (
    <FormControl fullWidth sx={{display:'flex',flexDirection:'column'}}>
    <InputLabel id="payment-status-label">Payment Status</InputLabel>
    <Select
      labelId="payment-status-label"
      value={newPaymentStatus}
      onChange={handlePaymentStatusChange}
    >
      <MenuItem value="Pending">Pending</MenuItem>
      <MenuItem value="Completed">Completed</MenuItem>
      
    </Select>
    <FormHelperText>Select the payment status</FormHelperText>
    <SaveIcon
    color={
      newPaymentStatus === "Completed"
        ? "success"
        : newPaymentStatus === "Failed"
        ? "error"
        : "primary"
    }
    onClick={handlePaymentUpdate}
    sx={{
      cursor: "pointer",
      fontSize: 20
    }}
  />
  </FormControl>
  );
};

export default PaymentStatusSelect;
