import { TextField, Box } from "@mui/material";
import { useState } from "react";

const ItemType = ({ setType }) => {
  const [item_type, setItemType] = useState("");

  const handleTypeChange = (e) => {
    const typeValue = e.target.value;
    setItemType(typeValue);
    setType(typeValue || ""); // If empty, send an empty string
  };

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        type="text"
        label="Type"
        fullWidth
        value={item_type}
        onChange={handleTypeChange}
      />
    </Box>
  );
};

export default ItemType;
