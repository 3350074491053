import React, { useState } from "react";
import { Box, Typography, Button, ButtonGroup } from "@mui/material";
import OrderDetail from "../orderTable/orderTable";
import ContactOrder from "../contactOrder/contactOrder";
import MakeMenuOrder from "../makeMenuOrder/makeMenuMain"; // Import the new component


const OrderManagement = () => {
  // State to manage selected component
  const [selectedTab, setSelectedTab] = useState("OrderDetail");

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
        Order Management
      </Typography>

      {/* Buttons to toggle between views */}
      <ButtonGroup
        sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}
      >
        <Button
          variant={selectedTab === "OrderDetail" ? "contained" : "outlined"}
          onClick={() => setSelectedTab("OrderDetail")}
        >
          Online Order Detail
        </Button>
        <Button
          variant={selectedTab === "ContactOrder" ? "contained" : "outlined"}
          onClick={() => setSelectedTab("ContactOrder")}
        >
          Contact Order
        </Button>
        <Button
          variant={selectedTab === "MakeMenuOrder" ? "contained" : "outlined"}
          onClick={() => setSelectedTab("MakeMenuOrder")}
        >
          Make Menu Order
        </Button>
      </ButtonGroup>

      {/* Conditional rendering */}
      {selectedTab === "OrderDetail" && <OrderDetail />}
      {selectedTab === "ContactOrder" && <ContactOrder />}
      {selectedTab === "MakeMenuOrder" && <MakeMenuOrder />}
    </Box>
  );
};

export default OrderManagement;
