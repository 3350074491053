import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import EditableTableRow from "./editableTableRow";
import PaginationControl from "./paginationControl";
// import axios from "axios";
import { toast } from "react-toastify";
import ApiAdmin from "../../config/AppAdmin";

// const url = 'https://www.bbgrestaurant.com/bbgapi'

const PaginatedItemTable = ({ submittedItems = [], onUpdate = () => {} }) => {
  const [items, setItems] = useState(
    Array.isArray(submittedItems[0]) ? submittedItems[0] : []
  );
  const [editIndex, setEditIndex] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editedItem, setEditedItem] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false); // New state for dialog
  const [itemToDelete, setItemToDelete] = useState(null); // State to store item to be deleted

  const deleteItemFromDatabase = async (sid) => {
    try {
      const response = await ApiAdmin.delete(`/joinData/${sid}`);
      if (response.status !== 200) {
        throw new Error("Failed to delete item");
      }
      setItems(items.filter((item) => item.sid !== sid));
      alert("Item deleted successfully");
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item); // Set the item to be deleted
    setDialogOpen(true); // Open the confirmation dialog
  };

  const handleDialogClose = () => {
    setDialogOpen(false); // Close the dialog without deleting
    setItemToDelete(null); // Reset the item to delete
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      deleteItemFromDatabase(itemToDelete.sid);
      setDialogOpen(false); // Close the dialog after deletion
      setItemToDelete(null); // Reset the item to delete
    }
  };

  const updateItemInDatabase = async (sid, updatedItem) => {
    try {
      const response = await ApiAdmin.patch(`/joinData/${sid}`, updatedItem);
      if (response.status === 200) {
        const updatedItems = items.map((item) =>
          item.sid === sid ? { ...item, ...updatedItem } : item
        );
        setItems(updatedItems);
        toast.success("Item updated successfully");
        return true;
      }
      throw new Error("Failed to update item");
    } catch (error) {
      console.error("Error updating item:", error);
      toast.error("Failed to update item");
      return false;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        overflowX: "scroll",
        fontSize: {
          xs: "4px",
          sm: "6px",
          md: "8px",
          lg: "10px",
        },
      }}
    >
      <Paper>
        <TableContainer sx={{ width: "100%" }}>
          <Table stickyHeader aria-label="paginated table">
            <TableHead>
              <TableRow
                sx={{
                  fontSize: {
                    xs: "6px", // for smaller screen sizes
                    sm: "8px", // for small screens
                    md: "10px", // for medium screens
                    lg: "12px", // for larger screens
                  },
                }}
              >
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Serial No.
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Item Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Category
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Subcategory
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Item Type
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Item Description
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Item Ingredients
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Item Image
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Item Price [Day,night]
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Popular [start time:end time]
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Special [start time:end time]
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Offer Festival [start time:end time]
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Instant Offer [start time:end time]
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  New Items [start time:end time]
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "12px" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={13} align="center">
                    No items found.
                  </TableCell>
                </TableRow>
              ) : (
                items
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <EditableTableRow
                      key={item.sid}
                      index={index}
                      item={item}
                      id={item.sid}
                      isEditing={editIndex === index}
                      editedItem={editedItem}
                      setEditIndex={setEditIndex}
                      setEditedItem={setEditedItem}
                      deleteItem={handleDeleteClick} // Update deleteItem handler
                      updateItem={updateItemInDatabase}
                      setItems={setItems}
                      sx={{ fontSize: "50px" }}
                    />
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationControl
          items={items}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PaginatedItemTable;
