import React, { useEffect, useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBarComponent from "./appBarComponent";
import DrawerComponent from "./drawerComponent";
import MenuItems from "./menuItems";
import AuthFormComponent from "./authFormComponent";
import ContentRenderer from "./contentRender";
import useOrders from "../orderTable/fetchedOrders";

const PermanentDrawerLeft = () => {
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Item Management");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [visitedItems, setVisitedItems] = useState([]);
  const { newOrderCount, resetOrderCount } = useOrders();
  const navigate = useNavigate();

  useEffect(() => {
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      const { isAuthenticated, role } = JSON.parse(storedAuthState);
      setIsAuthenticated(isAuthenticated);
      setRole(role);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (newOrderCount > 0) {
      setVisitedItems([]); // Reset so badges are visible again
      resetOrderCount(false);  // Prevent resetting to 0 but set to 1
    }
  }, [newOrderCount,resetOrderCount]);
  

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (!visitedItems.includes(item)) {
      setVisitedItems((prev) => [...prev, item]); // Mark as visited when clicked
    }
  };

  const handleAuthSubmit = ({ isAuthenticated, role }) => {
    setIsAuthenticated(isAuthenticated);
    setRole(role);
    localStorage.setItem(
      "authState",
      JSON.stringify({ isAuthenticated, role, selectedItem })
    );
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setRole(null);
    localStorage.removeItem("authState");
    navigate("/");
  };

  if (!isAuthenticated) {
    return <AuthFormComponent onAuthSubmit={handleAuthSubmit} />;
  }

  const menuItems = MenuItems({ role, newOrderCount, selectedItem, visitedItems });

  return (
    <Box sx={{ display: "flex", m: 8 }}>
      <CssBaseline />
      <AppBarComponent open={open} onDrawerOpen={() => setOpen(true)} onLogout={handleLogout} />
      <DrawerComponent
        open={open}
        onDrawerClose={() => setOpen(false)}
        menuItems={menuItems}
        selectedItem={selectedItem}
        onItemSelect={handleItemClick}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <ContentRenderer selectedItem={selectedItem} role={role} />
      </Box>
    </Box>
  );
};

export default PermanentDrawerLeft;