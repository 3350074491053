import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TextField,
} from "@mui/material";
import useOrders from "./fetchedOrders"; // Import your custom hook
import PaginationControl from "./pagination";
import TableHeader from "./tableHeader";
import OrderRow from "./orderRow";
import ApiAdmin from "../../config/AppAdmin";
import { updateOrderStatus } from "./updateOrderStatus";

const PaginatedOrderTable = () => {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTrackingId, setSearchTrackingId] = useState("");
  const [searchTrackStatus, setSearchTrackStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  

  // Use the custom hook to fetch orders
  const { data: orders = [] } = useOrders();
  // console.log("data ",orders);
  const sortedOrders = orders.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  useEffect(() => {
    let filtered = sortedOrders;
  
    if (searchTrackingId) {
      filtered = filtered.filter((order) =>
        order.tracking_id?.toLowerCase().includes(searchTrackingId.toLowerCase())
      );
    }
  
    if (searchTrackStatus) {
      filtered = filtered.filter((order) =>
        order.track_status?.toLowerCase().includes(searchTrackStatus.toLowerCase())
      );
    }
  
    if (selectedDate) {
      filtered = filtered.filter((order) => {
        const orderDate = new Date(order.created_at).toISOString().split("T")[0];
        return orderDate === selectedDate;
      });
    }
  
    if (selectedTime) {
      filtered = filtered.filter((order) => {
        const orderTime = new Date(order.created_at).toISOString().split("T")[1].slice(0, 5);
        return orderTime >= selectedTime;
      });
    }
  
    setFilteredOrders(filtered);
  }, [sortedOrders, searchTrackingId, searchTrackStatus, selectedDate, selectedTime]);
  

  const updatePaymentStatus = async (order_id, newPaymentStatus) => {
    try {
      const response = await ApiAdmin.patch(
        `/joinOrder/paymentStatus/${order_id}`,
        {
          payment_status: newPaymentStatus,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to update payment status");
      }

      alert("Payment status updated successfully");
    } catch (error) {
      console.error("Error updating payment status:", error);
      alert("Error updating payment status. Please try again.");
    }
  };

 

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        overflowX: "scroll",
        fontSize: { xs: "4px", sm: "6px", md: "8px", lg: "8px" },
      }}
    >
      <TextField
        label="Search by Tracking ID"
        value={searchTrackingId}
        onChange={(e) => setSearchTrackingId(e.target.value)}
        sx={{ mb: 2 }}
        fullWidth
      />
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          label="Filter by Date"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Filter by Time"
          type="time"
          value={selectedTime}
          onChange={(e) => setSelectedTime(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Search by Tracking Status"
          value={searchTrackStatus}
          onChange={(e) => setSearchTrackStatus(e.target.value)}
        />
      </Box>
      <Paper>
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableHeader />
            <TableBody>
              {filteredOrders.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={16}
                    align="center"
                    sx={{ fontSize: "4px" }}
                  >
                    No orders found for the selected filters.
                  </TableCell>
                </TableRow>
              ) : (
                filteredOrders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, index) => (
                    <OrderRow
                      key={order.status_id || index}
                      order={order}
                      index={index}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onStatusUpdate={updateOrderStatus}
                      onPaymentUpdate={updatePaymentStatus}
                    />
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationControl
          items={filteredOrders}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PaginatedOrderTable;
