import React, { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Tooltip,
  Button,
  Popover,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";

import PaymentStatusSelect from "./paymentStatus";
import { updateOrderStatus } from "./updateOrderStatus";

const OrderRow = ({
  order,
  index,
  page,
  rowsPerPage,
  onStatusUpdate,
  onPaymentUpdate,
}) => {


    const [status, setStatus] = useState(order.track_status);
  const [newPaymentStatus, setNewPaymentStatus] = useState(
    order.payment_status
  );
  const [anchorEl, setAnchorEl] = useState(null); // Popover anchor state

  useEffect(() => {
    setStatus(order.track_status);
  }, [order.track_status]);

  const handleStatusChange = async (newStatus) => {
    setStatus(newStatus); // Update UI immediately
    await updateOrderStatus(order.order_id, newStatus);
    if (onStatusUpdate) onStatusUpdate(order.order_id, newStatus);
    setAnchorEl(null);
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const parseCustomerDetails = (key) => {
    try {
      const customer = JSON.parse(order.customer_detail);
      return customer[key] || `No ${key} provided`;
    } catch {
      return "Invalid data";
    }
  };

  const handlePaymentUpdate = () => {
    onPaymentUpdate(order.order_id, newPaymentStatus);
  };

  const handlePaymentStatusChange = (e) => {
    setNewPaymentStatus(e.target.value);
  };

  const formatDateTime = (dateTime) => {
    try {
      const date = new Date(dateTime);
      return date.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      });
    } catch {
      return "Invalid Date";
    }
  };

  // Function to check if the order is older than 10 minutes
  const isOlderThan10Minutes = (createdAt) => {
    const currentTime = new Date().getTime();
    const orderTime = new Date(createdAt).getTime();
    return currentTime - orderTime > 10 * 60 * 1000; // 10 minutes in milliseconds
  };

  
  return (
    <TableRow>
      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
      <TableCell>
        <Tooltip title={`Date and time  ${formatDateTime(order.created_at)}`}>
          <span>{formatDateTime(order.created_at)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.customer_detail || "No details"}>
          <span>{parseCustomerDetails("name")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.customer_detail || "No details"}>
          <span>{parseCustomerDetails("address")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Click to call this number">
          <span>{parseCustomerDetails("phone")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.customer_detail || "No email"}>
          <span>{parseCustomerDetails("email")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.order_instructions || "No instructions"}>
          <span>{order.order_instructions || "No instructions"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={order.order_address_instruction || "No address instructions"}
        >
          <span>
            {order.order_address_instruction || "No address instructions"}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={(() => {
            try {
              const items = JSON.parse(order.order_items);
              return items
                .map((item) => `${item.name}=${item.quantity}`)
                .join(", ");
            } catch {
              return "Invalid data";
            }
          })()}
        >
          <span>
            {(() => {
              try {
                const items = JSON.parse(order.order_items);
                return items
                  .map((item) => `${item.name}=${item.quantity}`)
                  .join(", ");
              } catch {
                return "Invalid data";
              }
            })()}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.order_quantity || "N/A"}>
          <span>{order.order_quantity || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.total_price || "N/A"}>
          <span>{order.total_price || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.table_no || "N/A"}>
          <span>{order.table_no || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.tracking_id || "N/A"}>
          <span>{order.tracking_id || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <TableCell>
          <Button variant="outlined" onClick={handleClick}>
            {status}
          </Button>
          {/* Popover for selecting status */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h6"> Order Status</Typography>
              <MenuItem onClick={() => handleStatusChange("Accepted")}>
                Accept Order
              </MenuItem>
            </Box>
          </Popover>
        </TableCell>
      </TableCell>
      <TableCell>
        <Tooltip title={order.payment_mode || "N/A"}>
          <span>{order.payment_mode || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <PaymentStatusSelect
          handlePaymentStatusChange={handlePaymentStatusChange}
          handlePaymentUpdate={handlePaymentUpdate}
          newPaymentStatus={newPaymentStatus}
        />
      </TableCell>
      {isOlderThan10Minutes(order.created_at) ? (
        <TableCell>
          <span style={{ color: "green", fontWeight: "bold" }}>
            Accept order
          </span>
        </TableCell>
      ) : (
        <TableCell>
          <span style={{ color: "red", fontWeight: "bold" }}>Wait</span>
        </TableCell>
      )}
    </TableRow>
  );
};

export default OrderRow;
