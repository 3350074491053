import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for Toast notifications
import "./App.css";
import PermanentDrawerLeft from "./components/drawer/drawer";

function App() {
  return (
    <Router>
      {/* ToastContainer to display toast notifications */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Routes>
        {/* Example routes */}
        {/* Uncomment and customize as needed */}
        {/* <Route path="/login" element={<LoginForm />} /> */}
        <Route path="/" element={<PermanentDrawerLeft />} />
      </Routes>
    </Router>
  );
}

export default App;
