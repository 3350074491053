import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Box
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";

const AppBarComponent = ({ open, onDrawerOpen, onLogout }) => {
  return (
    <MuiAppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerOpen}
          edge="start"
          sx={[{ marginRight: 5 }, open && { display: "none" }]}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title="Logout">
          <IconButton color="inherit" onClick={onLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBarComponent;
