import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StaffForm from "./staffForm";
import StaffDetail from "./staffDetail";

const pages = ["Register staff", "Detail"];

function ResponsiveAppBar() {
  const [showStaffForm, setShowStaffForm] = useState(true);
  const [showStaffDetail, setStaffDetail] = useState(false);

  const handleMenuClick = (page) => {
    if (page === "Register staff") {
      setShowStaffForm(true);
      setStaffDetail(false); // Hide StaffDetail when showing StaffForm
    } else if (page === "Detail") {
      setShowStaffForm(false); // Hide StaffForm when showing StaffDetail
      setStaffDetail(true);
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "#000",
          marginBottom: "1rem",
        }}
      >
        Staff Management
      </Typography>

      {/* Button Navigation */}
      <Box sx={{ marginBottom: "1rem" }}>
        {pages.map((page) => (
          <Button
            key={page}
            onClick={() => handleMenuClick(page)}
            sx={{
              my: 1,
              // border:"1px solid gray",
              // backgroundColor:"lightgreen",
              m:1,
              color: "#000",
              fontSize: "0.9rem",
              fontWeight: "bold",
              textAlign: "left",
              justifyContent: "flex-start",
              width: "auto",
              textTransform: "none",
              padding: "0.5rem 1rem",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "rgba(203, 168, 168, 0.1)",
              },
            }}
          >
            {page}
          </Button>
        ))}
      </Box>

      {/* Conditional Rendering for StaffForm and StaffDetail */}
      <Box sx={{ padding: "1rem" }}>
        {showStaffForm && <StaffForm />}
        {showStaffDetail && <StaffDetail />}
      </Box>
    </Box>
  );
}

export default ResponsiveAppBar;
