import React from "react";
import { Box } from "@mui/material";
import ItemManagement from "../ItemManagement/itemManagement";
import OrderManagement from "../orderManagement/orderManagement";
import Enquiry from "../../forms/enquiryTable/enquiry";
import BookTable from "../tableBook/tableBook";
import Kitchen from "../kitchen/kitchenMain";
import StaffManagement from "../staff/staffNav";

const ContentRenderer = ({ selectedItem, role }) => {
  switch (selectedItem) {
    case "Item Management":
      return role === "super_admin" ||
        role === "admin" ||role==="staff" ||
        role === "kitchen" ? (
        <ItemManagement />
      ) : (
        <Box>Access Denied</Box>
      );
    case "Staff Management":
      return role === "super_admin" ? (
        <StaffManagement />
      ) : (
        <Box>Access Denied</Box>
      );
    case "Order Management":
      return <OrderManagement />;
    case "Enquiry Management":
      return <Enquiry />;
    case "Book Table Management":
      return <BookTable />;
    case "Kitchen":
      return <Kitchen />;
    default:
      return <Box>Select an option from the menu</Box>;
  }
};

export default ContentRenderer;
