import React from 'react';
import { Popover, Box, Typography, MenuItem } from '@mui/material';

const OrderStatusPopover = ({ anchorEl, handleClose, handleStatusChange }) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ color: 'green' }}>Order Status</Typography>
        <MenuItem onClick={() => handleStatusChange('cooking')}>Cooking</MenuItem>
        <MenuItem onClick={() => handleStatusChange('packing')}>Packing</MenuItem>
        <MenuItem onClick={() => handleStatusChange('ready')}>Ready</MenuItem>
      </Box>
    </Popover>
  );
};

export default OrderStatusPopover;
