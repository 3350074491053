import React, { useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
// import axios from "axios";
import CategorySelect from "./categorySelect";
import ItemNameSelect from "./itemName";
import SubcategorySelect from "./subCategorySelect";
import ImageUpload from "./imageUpload";
import PriceInput from "./itemPrice";
import IngredientInput from "./itemIngredients";
import Description from "./itemDescription";
import ItemType from "./itemType";
import { toast } from "react-toastify";
import ApiAdmin from "../../config/AppAdmin";

// const url = "https://www.bbgrestaurant.com/bbgapi";

const ItemAdd = ({ availableItems }) => {
  const [itemName, setItemName] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [itemType, setType] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [prices, setPrices] = useState([]);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [, setItemId] = useState("");

  const fetchItemId = async (itemName, category) => {
    try {
      const response = await ApiAdmin.get(`/item`, {
        params: { item_name: itemName, category },
      });
      const items = response.data.allItemsGot;
      const item = items.find(
        (item) =>
          item.item_name === itemName &&
          item.item_category.replace(/"/g, "") === category
      );
      if (item && item.item_id) return item.item_id;
      throw new Error("Item ID not found");
    } catch (error) {
      toast.error("Failed to retrieve item ID.");
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image || image.size > 1 * 1024 * 1024) {
      toast.error("Please upload an image under 1MB.");
      return;
    }

    const selectedCategory = category || "0";
    const fetchedItemId = await fetchItemId(itemName, selectedCategory);
    if (!fetchedItemId) return;

    const data = new FormData();
    data.append("item_subcategory", subcategory);
    data.append("item_type", itemType || "");
    data.append("item_image", image);
    data.append("item_description", description);
    data.append("item_ingredients", ingredients);
    data.append("item_price", prices);
    data.append("id", fetchedItemId);

    try {
      const response = await ApiAdmin.post(`/itemSubcategory`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.message === "Item added successfully.") {
        toast.success("Item added successfully.");
        setSubcategory("");
        setType("");
        setImage(null);
        setIngredients([]);
        setPrices([]);
        setItemId("");
        e.target.reset();
      } else {
        toast.success("Items added successfully.");
      }
    } catch (err) {
      toast.error("Item upload failed.");
    }
  };

  return (
    <Box
      component="form"
      method="post"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 800,
        margin: "auto",
        padding: 2,
        border: "2px solid black",
        flexDirection: "column",
        textAlign: "center",
        mb:5
      }}
    >
      <Typography sx={{ fontWeight: "bold", textTransform: "uppercase", mb: 2 }}>
        Add Item
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ItemNameSelect
            itemName={itemName}
            setItemName={setItemName}
            availableItems={availableItems}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CategorySelect category={category} setCategory={setCategory} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SubcategorySelect
            category={category}
            subcategory={subcategory}
            setSubcategory={setSubcategory}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ItemType setType={setType} />
        </Grid>
        <Grid item xs={12} md={6}>
          <IngredientInput
            ingredients={ingredients}
            setIngredients={setIngredients}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PriceInput prices={prices} setPrices={setPrices} />
        </Grid>
        <Grid item xs={12}>
          <ImageUpload setImage={setImage} />
        </Grid>
        <Grid item xs={12}>
          <Description setDescription={setDescription} />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default ItemAdd;
