import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders: (state, action) => {
      console.log("Redux: Storing orders", action.payload);
      return { ...state, data: action.payload, loading: false };
    },
    setLoading: (state) => {
      return { ...state, loading: true };
    },
    setError: (state, action) => {
      return { ...state, error: action.payload, loading: false };
    },
  },
});

export const { setOrders, setLoading, setError } = orderSlice.actions;
export default orderSlice.reducer;
