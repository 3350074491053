let socketUrl = "";


const dynamicSocketUrl = () => {
  try {
    if (window.location.hostname === "localhost") {
      socketUrl = "http://localhost:8001";
    } else {
      socketUrl = "https://www.bbgfood.com";
    }
    return socketUrl;
  } catch (error) {
    console.log("error:", error);
  }
};

export default dynamicSocketUrl;
