import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const AdminDataGrid = ({ admins, onEditClick }) => {
  const columns = [
    { field: "aid", headerName: "SN", width: 100 },
    { field: "admin_name", headerName: "Name", width: 200 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "address", headerName: "Address", width: 200 },
    { field: "admin_contact", headerName: "Contact", width: 350 },
    { field: "role", headerName: "Role", width: 150 },
    { field: "salary", headerName: "Salary", width: 200 },
    { field: "salary_status", headerName: "Salary Status", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => onEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={admins.map((admin, index) => ({ id: index, ...admin }))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
      />
    </div>
  );
};

export default AdminDataGrid;
