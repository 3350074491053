import React, { useState, useCallback } from "react";
import { Buffer } from "buffer";

const ItemImage = ({ isEditing, editedItem, item, setEditedItem, setImageFile }) => {
  const [previewImage, setPreviewImage] = useState(null);

  const getItemImageSrc = useCallback(() => {
    if (item.item_image && item.item_image.data) {
      return `data:${item.mimeType};base64,${Buffer.from(item.item_image.data).toString("base64")}`;
    }
    return null;
  }, [item]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        setPreviewImage(imageData);
        setImageFile(file);  // Set the image file for further processing
        setEditedItem((prevEditedItem) => ({
          ...prevEditedItem,
          item_image: {
            data: imageData.split(',')[1],  // Get the Base64 encoded part of the image
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return isEditing ? (
    <>
      <input
        type="file"
        name="item_image"
        accept="image/*"
        onChange={handleImageChange}
      />
      {previewImage || getItemImageSrc() ? (
        <img
          src={previewImage || getItemImageSrc()}
          alt={editedItem?.item_name || "Uploaded Image"}
          width="50"
          height="50"
        />
      ) : (
        <p>No Preview Available</p>
      )}
    </>
  ) : item.item_image ? (
    <img
      src={getItemImageSrc()}
      alt={item.item_name || "Item Image"}
      width="50"
      height="50"
    />
  ) : (
    <p>No Image</p>
  );
};

export default ItemImage;
