import React, { useState } from "react";
import { Container, Paper, Link } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import ApiAdmin from "../../config/AppAdmin";
import FormHeader from "./form_header";
import SignInForm from "./signin_form";
import ResetPasswordForm from "./reset_password_form";

// const url = "https://www.bbgrestaurant.com/bbgapi";

const AuthForm = ({ onAuthSubmit }) => {
  const [formData, setFormData] = useState({
    admin_contact: "",
    admin_password: "",
    otp: "",
    new_password: "",
    confirm_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateContact = (admin_contact) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;
    if (!emailRegex.test(admin_contact) && !phoneRegex.test(admin_contact)) {
      toast.error(
        "Invalid contact: Enter a valid email or 10-digit phone number."
      );
      return false;
    }
    return true;
  };
  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!validateContact(formData.admin_contact)) return;
  
    const payload = {
      admin_contact: formData.admin_contact,
      admin_password: formData.admin_password,
    };
  
    try {
      const response = await ApiAdmin.post(`/admin/login`, payload);
      const { role, token } = response.data.data;
  
      toast.success("Login successful.");
  
      // Store role and token securely
      localStorage.setItem("userRole", role);
      localStorage.setItem("token", token);
  
      onAuthSubmit({ isAuthenticated: true, role }); // Pass the role and authentication status
  
      navigate("/"); // Redirect immediately to dashboard
    } catch (error) {
      toast.error("Login failed. Please try again.");
      // console.error(error);
    }
  };
  

  const handleOtpSend = async (e) => {
    e.preventDefault();
    if (!validateContact(formData.admin_contact)) return;

    try {
      const otp = Math.floor(100000 + Math.random() * 900000).toString();
      await ApiAdmin.post(`/admin/save-otp`, {
        admin_contact: formData.admin_contact,
        otp: otp,
      });
      toast.success("OTP has been sent to your email.");
      setOtpSent(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    }
  };

  const handleOtpVerify = async (e) => {
    e.preventDefault();

    try {
      await ApiAdmin.post(`/admin/verify-otp`, {
        admin_contact: formData.admin_contact,
        otp: formData.otp,
      });
      toast.success("OTP verified successfully.");
      setOtpVerified(true);
    } catch (error) {
      toast.error(error.response?.data?.message || "OTP verification failed.");
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (formData.new_password !== formData.confirm_password) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      await ApiAdmin.post(`/admin/reset-password`, {
        admin_contact: formData.admin_contact,
        new_password: formData.new_password,
      });
      toast.success("Password updated successfully.");
      setIsResettingPassword(false);
      setOtpSent(false);
      setOtpVerified(false);
      setFormData({
        admin_contact: "",
        admin_password: "",
        otp: "",
        new_password: "",
        confirm_password: "",
      });
    } catch (error) {
      toast.error(error.response?.data?.message || "Password reset failed.");
    }
  };

  const toggleResetPassword = () => {
    setIsResettingPassword(!isResettingPassword);
    setOtpSent(false);
    setOtpVerified(false);
    setFormData({
      admin_contact: "",
      admin_password: "",
      otp: "",
      new_password: "",
      confirm_password: "",
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 4, mt: 8 }}>
        {isResettingPassword ? (
          <>
            <FormHeader title="Reset Password" />
            <ResetPasswordForm
              formData={formData}
              otpSent={otpSent}
              otpVerified={otpVerified}
              handleInputChange={handleInputChange}
              handleOtpSend={handleOtpSend}
              handleOtpVerify={handleOtpVerify}
              handlePasswordReset={handlePasswordReset}
            />
          </>
        ) : (
          <>
            <FormHeader title="Admin Sign In" />
            <SignInForm
              formData={formData}
              showPassword={showPassword}
              handleInputChange={handleInputChange}
              handleSignIn={handleSignIn}
              setShowPassword={setShowPassword}
            />
          </>
        )}
        <Link
          component="button"
          onClick={toggleResetPassword}
          variant="body2"
          sx={{ mt: 2 }}
        >
          {isResettingPassword ? "Back to Sign In" : "Forgot Password?"}
        </Link>
      </Paper>
    </Container>
  );
};

export default AuthForm;