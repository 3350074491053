import React from "react";
import {
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Box,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const DrawerComponent = ({
  open,
  onDrawerClose,
  menuItems,
  selectedItem,
  onItemSelect,
}) => {
  return (
    <Drawer
      variant={open ? "permanent" : "temporary"} // Switch between permanent and temporary
      open={open}
      onClose={onDrawerClose} // Handle closing for temporary drawer
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0 16px",
        }}
      >
        <IconButton onClick={onDrawerClose}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      <Divider />
      <List>
        {menuItems.map(({ text, icon }) => (
          <Tooltip title={text} key={text}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => onItemSelect(text)}
                selected={selectedItem === text}
              >
                <ListItemIcon>{icon}</ListItemIcon> {/* Ensure icons are displayed */}
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </Drawer>
  );
};

export default DrawerComponent;
