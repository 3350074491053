import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { fetchMenuData } from "../../redux/slice/makeMenuDataSlice";

const MenuTable = () => {
  const dispatch = useDispatch();
  const { data: menuData, loading } = useSelector((state) => state.menu);
  
  useEffect(() => {
    dispatch(fetchMenuData());
  }, [dispatch]);

  // Grouping details uniquely by customer while merging items
  const rows = useMemo(() => {
    const uniqueRows = [];

    menuData.forEach((menu) => {
      const schedule = JSON.parse(menu.menu_schedule || "{}");
      const customerMap = new Map();

      menu.details?.forEach((detail) => {
        const customerKey = `${menu.menu_name}-${detail.customer_name}-${detail.customer_contact}`;

        if (!customerMap.has(customerKey)) {
          customerMap.set(customerKey, {
            id: customerKey,
            menu_name: menu.menu_name,
            schedule: `${schedule.date} at ${schedule.time}`,
            customer_name: detail.customer_name || "N/A",
            customer_contact: detail.customer_contact || "N/A",
            customer_address: detail.customer_address || "N/A",
            items: new Set(), // Using Set to avoid duplicate items
            total_price: detail.total_price || "N/A",
            is_subscribed: detail.is_subscribed ? "Yes" : "No",
            subscription_type: detail.subscription_type || "N/A",
            subscription_discount: detail.subscription_discount || "N/A",
          });
        }

        customerMap.get(customerKey).items.add(detail.item_name || "N/A");
      });

      customerMap.forEach((row) => {
        row.items = Array.from(row.items).join(", "); // Convert Set to string
        uniqueRows.push(row);
      });
    });

    return uniqueRows;
  }, [menuData]);

  // Define columns for the DataGrid
  const columns = [
    { field: "menu_name", headerName: "Menu", width: 150 },
    { field: "schedule", headerName: "Schedule", width: 200 },
    { field: "customer_name", headerName: "Customer", width: 150 },
    { field: "customer_contact", headerName: "Contact", width: 150 },
    { field: "customer_address", headerName: "Address", width: 200 },
    { field: "items", headerName: "Items", width: 200 },
    { field: "total_price", headerName: "Total Price", width: 120 },
    { field: "is_subscribed", headerName: "Subscribed", width: 120 },
    { field: "subscription_type", headerName: "Subscription Type", width: 150 },
    { field: "subscription_discount", headerName: "Discount", width: 120 },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1700px",
        margin: "auto",
        mt: 5,
        px: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 15]}
        loading={loading}
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            fontFamily: "roboto-serif",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-cell": {
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
          },
        }}
      />
    </Box>
  );
};

export default MenuTable;
