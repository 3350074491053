import React from "react";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import { Badge } from "@mui/material";

const MenuItems = ({ role, newOrderCount, selectedItem, visitedItems }) => {
  const roleBasedMenu = {
    super_admin: [
      { text: "Item Management", icon: <InventoryIcon /> },
      { text: "Staff Management", icon: <GroupIcon /> },
      {
        text: "Order Management",
        icon: visitedItems.includes("Order Management") || newOrderCount === 0 ? (
          <ShoppingCartIcon />
        ) : (
          <Badge badgeContent={newOrderCount} color="error">
            <ShoppingCartIcon />
          </Badge>
        ),
      },
      { text: "Enquiry Management", icon: <ContactSupportIcon /> },
      { text: "Book Table Management", icon: <TableRestaurantIcon /> },
      {
        text: "Kitchen",
        icon: visitedItems.includes("Kitchen") || newOrderCount === 0 ? (
          <RestaurantIcon />
        ) : (
          <Badge badgeContent={newOrderCount} color="error">
            <RestaurantIcon />
          </Badge>
        ),
      },
    ],
    admin: [
      { text: "Item Management", icon: <InventoryIcon /> },
      {
        text: "Order Management",
        icon: visitedItems.includes("Order Management") || newOrderCount === 0 ? (
          <ShoppingCartIcon />
        ) : (
          <Badge badgeContent={newOrderCount} color="error">
            <ShoppingCartIcon />
          </Badge>
        ),
      },
      { text: "Enquiry Management", icon: <ContactSupportIcon /> },
      { text: "Book Table Management", icon: <TableRestaurantIcon /> },
      {
        text: "Kitchen",
        icon: visitedItems.includes("Kitchen") || newOrderCount === 0 ? (
          <RestaurantIcon />
        ) : (
          <Badge badgeContent={newOrderCount} color="error">
            <RestaurantIcon />
          </Badge>
        ),
      },
    ],
    staff: [
      { text: "Item Management", icon: <InventoryIcon /> },
      {
        text: "Order Management",
        icon: visitedItems.includes("Order Management") || newOrderCount === 0 ? (
          <ShoppingCartIcon />
        ) : (
          <Badge badgeContent={newOrderCount} color="error">
            <ShoppingCartIcon />
          </Badge>
        ),
      },
      { text: "Book Table Management", icon: <TableRestaurantIcon /> },
    ],
    kitchen: [
      { text: "Item Management", icon: <InventoryIcon /> },
      {
        text: "Order Management",
        icon: visitedItems.includes("Order Management") || newOrderCount === 0 ? (
          <ShoppingCartIcon />
        ) : (
          <Badge badgeContent={newOrderCount} color="error">
            <ShoppingCartIcon />
          </Badge>
        ),
      },
      { text: "Book Table Management", icon: <TableRestaurantIcon /> },
      {
        text: "Kitchen",
        icon: visitedItems.includes("Kitchen") || newOrderCount === 0 ? (
          <RestaurantIcon />
        ) : (
          <Badge badgeContent={newOrderCount} color="error">
            <RestaurantIcon />
          </Badge>
        ),
      },
    ],
  };

  return roleBasedMenu[role] || [];
};

export default MenuItems;
