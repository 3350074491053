import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../config/AppAdmin";
import { toast } from "react-toastify";

// Define an initial state for the slice
export const fetchAdmins = createAsyncThunk("admins/fetchAdmins", async () => {
  try {
    const response = await ApiClient.get("admin");

    // Validate data structure
    if (
      !Array.isArray(response.data.data) ||
      !Array.isArray(response.data.data[0])
    ) {
      throw new Error("Invalid data structure: Expected nested arrays");
    }

    return response.data.data[0];
  } catch (error) {
    console.error("Error fetching admins:", error);
    throw error; // Re-throw error to be handled by thunk
  }
});

// Define an async thunk for updating an admin
export const updateAdmin = createAsyncThunk(
  "admins/updateAdmin",
  async ({ aid, admin_name, admin_contact, phone, address,role, salary, salary_status }) => {
    try {
      const response = await ApiClient.patch(`admin/${aid}`, {
        admin_name,
        admin_contact,
        phone,
        address,
        role,
        salary,
        salary_status,
      });

      return response.data; // Return the updated admin data
      
    } catch (error) {
      console.error("Error updating admin:", error);
      throw error; // Re-throw error to be handled by thunk
    }
  }
);

// Create a slice for managing admin data state
const adminSlice = createSlice({
  name: "staff",
  initialState: {
    admins: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmins.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error before fetching new data
      })
      .addCase(fetchAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload; // Store fetched data
      })
      .addCase(fetchAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store error message
      })
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error before update
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.loading = false;
        // Update the specific admin data in the state
        const updatedAdmin = action.payload;
        const index = state.admins.findIndex((admin) => admin.aid === updatedAdmin.aid);
        if (index !== -1) {
          state.admins[index] = updatedAdmin; // Replace with updated data
        }
        toast.success("updated successfully");
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store error message
        toast.error("Failed to update : " + action.error.message);
      });
  },
});

export default adminSlice.reducer;
