import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import ContactOrderForm from "./contactOrderForm";
import ContactOrderTable from "./contactOrderTable";
// import axios from "axios";
import ApiAdmin from "../../config/AppAdmin";

// const url = "https://www.bbgrestaurant.com/bbgapi";
const OrderManager = () => {
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    address: "",
    phone: "",
    orderItems: "",
    orderQuantity: "",
    totalPrice: "",
    paymentStatus: "",
    trackStatus: "",
  });
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState("");

  const handleFormChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await fetch(`http://localhost:8001/bbgapi/contactOrder`);
      if (!response.ok) throw new Error("Failed to fetch orders");
      const data = await response.json();
      console.log("fetched contact order", data);

      setRows(data.data[0] || []); // Set the fetched orders directly
    } catch (error) {
      console.error("Error fetching orders:", error);
      alert("Failed to fetch orders.");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const isEditing = !!formData.id; // Check if `id` exists (edit mode)
    try {
      const apiUrl = isEditing
        ? `/contactOrder/${formData.id}`
        : `/contactOrder`;

      const response = await ApiAdmin({
        method: isEditing ? "PATCH" : "POST",
        url: apiUrl,
        headers: { "Content-Type": "application/json" },
        data: {
          id: formData.id,
          name: formData.name,
          address: formData.address,
          contact_number: formData.phone,
          order_items: formData.orderItems,
          order_quantity: formData.orderQuantity,
          total_price: formData.totalPrice,
          payment_status: formData.paymentStatus,
          track_status: formData.trackStatus,
        },
      });

      console.log(response);
      alert(
        isEditing
          ? "Order updated successfully!"
          : "Order created successfully!"
      );

      setFormData({
        id: null,
        name: "",
        address: "",
        phone: "",
        orderItems: "",
        orderQuantity: "",
        totalPrice: "",
        paymentStatus: "",
        trackStatus: "",
      });

      fetchOrders();
    } catch (error) {
      console.error(
        isEditing ? "Error updating the order:" : "Error creating the order:",
        error
      );
    }
  };

  const handleEdit = (row) => {
    setFormData({
      id: row.id,
      name: row.name,
      address: row.address,
      phone: row.contact_number,
      orderItems: row.order_items,
      orderQuantity: row.order_quantity,
      totalPrice: row.total_price,
      paymentStatus: row.payment_status,
      trackStatus: row.track_status,
    });
  };

  // Apply filter based on the selected date
  const filteredRows = dateFilter
    ? rows.filter((row) => row.created_at.startsWith(dateFilter)) // Use created_at for filtering
    : rows;

  return (
    <Box sx={{ p: 3 }}>
      <TextField
        label="Filter by Date (YYYY-MM-DD)"
        type="date"
        value={dateFilter}
        onChange={(e) => setDateFilter(e.target.value)}
        sx={{ mb: 2 }}
        InputLabelProps={{ shrink: true }}
        fullWidth={false}
      />
      <ContactOrderForm
        formData={formData}
        onChange={handleFormChange}
        onSubmit={handleSave}
      />
      <ContactOrderTable
        rows={filteredRows}
        loading={loading}
        onEdit={handleEdit}
      />
    </Box>
  );
};

export default OrderManager;
