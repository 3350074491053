import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../config/AppAdmin";

// Async thunk to fetch menu data
export const fetchMenuData = createAsyncThunk(
  "menu/fetchMenuData",
  async () => {
    const response = await ApiClient.get(`/menu`);
    
    if (response.data.data) {
      // Group data by menu_id
      const groupedData = response.data.data.reduce((acc, item) => {
        if (!acc[item.menu_id]) {
          acc[item.menu_id] = {
            id: item.menu_id, // Unique ID for the menu
            menu_name: item.menu_name,
            menu_schedule: item.menu_schedule,
            details: [], // Array to hold all details under this menu_id
          };
        }

        // Concatenate item_name and item_subcategory
        const concatenatedItemName = item.item_subcategory
          ? ` ${item.item_subcategory} ${item.item_name} `
          : item.item_name;

        // Push the details into the details array
        acc[item.menu_id].details.push({
          customer_name: item.customer_name,
          customer_address: item.customer_address,
          customer_contact: item.customer_contact,
          item_name: concatenatedItemName, // Use concatenated item_name
          item_type: item.item_type || "N/A",
          total_price: item.total_price,
          is_subscribed: item.is_subscribed,
          subscription_type: item.subscription_type,
          subscription_discount: item.subscription_discount,
        });
        return acc;
      }, {});

      // Convert the grouped data into an array
      return Object.values(groupedData);
    }
    throw new Error("Invalid data format");
  }
);

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMenuData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchMenuData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default menuSlice.reducer;