import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ApiAdmin from "../../config/AppAdmin";

function BookTable() {
  const [filteredOrders, setFilteredOrders] = useState([]);

  // Fetch all orders
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await ApiAdmin.get(`/bookTable`);
        const fetchedOrders = response.data.allItemsGot || [];
        console.log("response", response);

        const ordersWithIds = fetchedOrders.map((order, index) => ({
          id: index + 1, // Add unique ID for DataGrid rows
          ...order,
          booking_menu:
            order.booking_menu && order.booking_menu.length > 0
              ? Object.values(order.booking_menu[0]).join(", ")
              : "N/A",
        }));
        setFilteredOrders(ordersWithIds);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setFilteredOrders([]); // Clear the table on error
      }
    };

    fetchOrders();
  }, []);

  // Handle the change in the 'is_booked' column
  const handleIsBookedChange = async (booking_id, newValue) => {
    try {
      // Find the order being updated
      const updatedOrder = filteredOrders.find((order) => order.booking_id === booking_id);
      if (!updatedOrder) {
        alert("Order not found");
        return;
      }

      // Update on server
      const response = await ApiAdmin.patch(`/bookTable/${booking_id}`, {
        column: "is_booked", // Specify column name
        value: newValue === "Yes" ? "1" : "0", // Convert Yes/No to 1/0
      });

      // Check if the response indicates success
      if (response.status === 200) {
        // Update locally in the state
        const updatedOrders = filteredOrders.map((order) =>
          order.booking_id === booking_id
            ? { ...order, is_booked: newValue === "Yes" ? "1" : "0" }
            : order
        );
        setFilteredOrders(updatedOrders);

        // Show success alert
        alert(`Booking status updated to "${newValue}" for Booking ID: ${booking_id}`);
      } else {
        // Show failure alert
        alert("Failed to update booking status. Please try again.");
      }
    } catch (error) {
      console.error("Error updating is_booked:", error);
      alert("An error occurred while updating the booking status.");
    }
  };

  // Define columns for the DataGrid
  const columns = [
    {
      field: "number_of_customer",
      headerName: "Number of Customers",
      width: 200,
    },
    { field: "table_number", headerName: "Table Number", width: 150 },
    { field: "timing", headerName: "Timing", width: 200 },
    { field: "booking_menu", headerName: "Booking Menu", width: 400 },
    {
      field: "additional_description",
      headerName: "Additional Description",
      width: 350,
    },
    {
      field: "is_booked",
      headerName: "Is Booked",
      width: 150,
      renderCell: (params) => (
        <select
          value={params.value === "1" ? "Yes" : "No"}
          onChange={(e) => handleIsBookedChange(params.row.booking_id, e.target.value)}
        >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: 500,
        width: "100%",
      }}
    >
      <div style={{ width: "80%" }}>
        <h1>Book Table</h1>
        <DataGrid
          rows={filteredOrders}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </div>
  );
}

export default BookTable;
