import React from "react";

const DateTimeInput = ({ label, value, onChange, error }) => (
  <label>
    {label}:
    <input
      type="datetime-local"
      value={value}
      onChange={onChange}
      style={{
        display: "block",
        margin: "8px 0",
        padding: "8px",
        border: error ? "1px solid red" : "1px solid #ccc",
        borderRadius: "4px",
      }}
    />
    {error && <div style={{ color: "red", fontSize: "12px" }}>{error}</div>}
  </label>
);

export default DateTimeInput;
