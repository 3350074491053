import { configureStore } from "@reduxjs/toolkit";
//configureStore manage middleware,reducer,devtools support as built-in 

//without configureStore

// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

// const store = createStore(rootReducer, applyMiddleware(thunk));

// A Redux store is a centralized container that holds the state of your application. 

import menuReducer from "./slice/makeMenuDataSlice";
import staffReducer from "./slice/staffSlice";
import orderReducer from "./slice/orderSlice"

const store = configureStore({
  reducer: {
    menu: menuReducer,
    staff:staffReducer,
    orders:orderReducer
  },
});

export default store;
