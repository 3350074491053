import axios from 'axios';

const ApiClient = axios.create({
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add an interceptor to dynamically switch base URL
ApiClient.interceptors.request.use((config) => {
    if (window.location.hostname === 'localhost') {
        config.baseURL = 'http://localhost:8001/bbgapi'; // Local server
    } else {
        config.baseURL = 'https://www.bbgrestaurant.com/bbgapi'; // Remote server
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default ApiClient;
