import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
// import axios from "axios";
import ParentComponent from "../../forms/ParentForm/ItemManagementForm";
import PaginatedItemTable from "../../forms/TableData/itemTable"; // Changed to PaginatedItemTable

import AppAdmin from "../../config/AppAdmin";

// const url = "https://www.bbgrestaurant.com/bbgapi/joinData";

const ItemManagement = () => {
  const [activeForm, setActiveForm] = useState("");
  const [combinedItems, setCombinedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submittedItems, setSubmittedItems] = useState([]);

  useEffect(() => {
    const fetchJoinData = async () => {
      try {
        const response = await AppAdmin.get(`/joinData`);
        const joinData = response.data;
        //  console.log("Join data", joinData.data);
        // Ensure joinData.data is an array
        const combinedItemsData = Array.isArray(joinData.data)
          ? joinData.data
          : [];
        setCombinedItems(combinedItemsData);
        // console.log("Combined Items", combinedItemsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchJoinData();
  }, []);

  const handleItemSubmit = (item) => {
    const isItemAlreadySubmitted = submittedItems.some(
      (submittedItem) => submittedItem.item_name === item.item_name
    );

    if (!isItemAlreadySubmitted) {
      setSubmittedItems([...submittedItems, item]);
    }
  };
  console.log("Handle submit", handleItemSubmit);

  const renderActiveForm = () => {
    switch (activeForm) {
      case "ItemAdd":
        return <ParentComponent showAddForm={true} />;
      case "ItemRegister":
        return <ParentComponent showAddForm={false} />;
      default:
        return null;
    }
  };
  const handleUpdate = (updatedItems) => {
    console.log("Updated items:", updatedItems);
    // Handle the updated items as needed
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 3,
          mb: 10,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color={activeForm === "ItemRegister" ? "secondary" : "primary"}
          onClick={() => setActiveForm("ItemRegister")}
          sx={{width:{xs:'100%',sm:'auto'}}}
        >
          Item Register
        </Button>
        <Button
          variant="contained"
          color={activeForm === "ItemAdd" ? "secondary" : "primary"}
          onClick={() => setActiveForm("ItemAdd")}
          sx={{width:{xs:'100%',sm:'auto'}}}
        >
          Item Add
        </Button>
      </Box>
      {renderActiveForm()}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error fetching data: {error}</p>
      ) : (
        <PaginatedItemTable
          submittedItems={combinedItems}
          onUpdate={handleUpdate}
        />
      )}
    </Box>
  );
};

export default ItemManagement;
